// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  isVersionBrowser: false,
  appVersion: '1.2.6', // infos bancaires
  GOOGLE_KEY_API: 'AIzaSyCPYUCP5IJKGRXu53J1rLIX3EivrxhxXOA',

//   urlFront: 'http://localhost:4200',
  // urlFront: 'https://imageprod.wanteez.eu/',
   urlFront: 'https://wanteez.eu/',
// 
    //   urlApi: 'http://localhost:8000',
  // urlApi: 'https://93ca-194-51-224-19.ngrok.io',
  //  urlApi: 'https://imageapi.wanteez.eu',
   urlApi: 'https://api.wanteez.eu',
  //  urlApi:'http://192.168.1.27:8000',
  // urlSocket: 'http://localhost:3000',
   urlSocket: 'https://websocket.wanteez.eu',

  urlApiImageProd: 'https://imageapi.wanteez.eu',
  urlSocketImageProd: 'https://websocket.imageprod.wanteez.eu',

//   stripeKey: 'pk_test_51JZEoJEX4JUeS8a3KOg8imOQz7xXiLJTPb9n2evTuLxuCo25GOB08JOZP8DDpsGRUjuZbIQz9xLEYo1yOl6eupXN00esew9YNY',

  //prod stripe public key
  stripeKey: 'pk_live_TuMYsfECP1qwm1LrOKEzkkG700bn6iyAst',

  encryptSecretKey: 'KUoYdXvTvlrG8MxnyBQ4t3KfcBDWTALX',
  phoneWanteez: '0474110532',
  mailServicePaie: 'paie@wanteez.fr',
  mailServiceRh: 'rh@wanteez.fr',
  showLogs: false,
  perimetreRechercheDefault: 20,
  metersArrivedAtClient: 50,
  metersLeftFromClient: 50
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

export interface Temoignage{
        nom: string;
        age?: number;
        note: number;
        temoignage: string;
        photo?: string;
        date?: any;
        origine?: string;
}


export const listTemoignages: Temoignage[] = [
        {
           nom: 'Mr GUILLAUE',
           date: '2021-07-30T17:02:00',
           temoignage: "Toute la famille remercie Madame Mélanie GAIN pour ses interventions à domicile pour mon père Mr Guillaume Jean-Claude. Mélanie est une personne d'une grande efficacité, ultra rapide dont le travail est impeccable et très agréable. Nous lui sommes reconnaissants de nous avoir soulager de ces tâches ménagères en ces moments difficiles. Nous lui souhaitons une bonne continuation et d'excellentes vacances bien méritées.",
           note: 5,
           origine: 'Google'
        },
       
        {
          nom: 'HETLMI21070119',
          date: '2021-07-28T08:35:00',
          temoignage: "Merci à Julie qui est intervenue mon domicile suite à un accident qui m a obligé à une immobilisation. Intervenante venant de poligny 39, personne agréable, travail sérieux.Julie est à l écoute et répond parfaitement à nos attentes.",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'francoise.moussier@orange.fr',
          date: '2021-07-23T21:34:00',
          temoignage: "Merci à Floriane pour son efficacité et sa bonne humeur, c'est la première fois que je fais appel à un prestataire de services grâce à ma mutuelle suite à un petit accident qui me permettait pas de faire certaines tâches ménagères et je suis ravie d'avoir découvert cette entreprise!.",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Therese-marie Millequand',
          date: '01/08/2021',
          temoignage: "Aspects positifs : Ponctualité, Professionnalisme, Qualité, Réactivité Bonne prestation de Roger. Travail propre et soigner. Toujours à l'heure ce qui ce fais rare de nos jours. Je recommande cette société et surtout Roger. Encore un grand merci   (google)",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Michel Ferrarotti',
          date: '01/09/2021',
          temoignage: "Aspects positifs : Ponctualité, Professionnalisme, Qualité, Réactivité Grande satisfaction de la prestation de notre aide à domicile; Aurélie, pour son dévouement, son sérieux et son efficacité, depuis son premier jour.   (google)",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Anais Carin Filiatre',
          date: '01/06/2021',
          temoignage: "Aspects positifs : Ponctualité, Professionnalisme, Qualité, Réactivité Roger est venu suite à notre demande auprès de notre mutuelle. 3 interventions. Nous avons eus à faire à quelqu’un de professionnel et très minutieux. 3 vendredi matins complets. Et c’est rare de voir quelqu’un qui va jusqu’à bouger les tapis, aller sous le canapé. Le bonheur de retrouver sa maison nickel. Moi je recommande, et ça va être dur d’ailleurs de refaire sans lui. Merci Roger pour votre professionnalisme!",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'alexandra FOURNIER',
          date: '01/08/2021',
          temoignage: "Je remercie Julie pour son sérieux et son application dans son travail. C'est une jeune femme dynamique, ponctuelle et efficace que je recommande vivement. Très bon relationnel avec ses clients.",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Sandra Delahaye',
          date: '01/07/2021',
          temoignage: "Merci à Roger de sa ponctualité, de son amabilité.  Il s'adapte facilement  et est très sympathique. Ménage de bonne qualité. J'aurai aimé que la possibilité pour modifier ses horaires de travail soit plus simple par la structure.",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'aurelie s.pires',
          date: '01/07/2021',
          temoignage: "Salaire motivant  ,des horaires au tops par rapport au enfants , personnels au top toujours à l écoute J adore ce métier j'adore ce que je fais, sa fait un très long moment je fais ce métier.. La société très professionnelle , (Je pense si on fait bien le travail et on et respectueux  tous vas bien)  ",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Hornus Sarah',
          date: '01/08/2021',
          temoignage: "Aspects positifs : Ponctualité, Professionnalisme, Qualité. Ce fut un plaisir de rencontré Aurélie. Elle a été adorable et très professionnelle. Nous conseillons les yeux fermés. Merci encore pour votre aide.",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Jean-paul BREHIER',
          date: '01/08/2021',
          temoignage: "Aspects positifs : Ponctualité, Professionnalisme, Qualité, Rapport qualité-prix. Félicitations à Mélanie G. ,pour son travail de qualité chez nous à Vannes (56),depuis déjà de nombreux mois. Professionnelle, constante dans la qualité, et largement au-dessus des attentes. La confiance s'est désormais installée. A recommander sans modération !  Bravo.",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'veronique bart',
          date: '01/07/2021',
          temoignage: "Roger s'est occupé de ma maman pendant plusieurs semaines.  Je le remercie pour sa gentillesse,  son professionalisme, sa ponctualité,  sa rigueur,  sa propreté et sa disponibilité.  Il a été un soutien précieux pour ma maman.  Je le conseille à 100%. Merci à lui.",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Francoise Vasseur',
          date: '01/06/2021',
          temoignage: " Aspects positifs : Ponctualité, Professionnalisme. Suite hospitalisation de mon mari,  Roger nous a été envoyé. Nous avons beaucoup apprécié son efficacité  dans l'action et son savoir être.  Grand merci",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Pierre Tixier',
          date: '01/06/2021',
          temoignage: "Aspects positifs : Ponctualité, Professionnalisme, Qualité, Réactivité. Bonjour, nous avons sollicité  l'entreprise H et L via notre mutuelle de santé et nous sommes très satisfaits de leur professionnalisme, très bonne réactivité. Concernant l'intervention de ménage à domicile, elle est tout simplement parfaite. Mélanie Gain qui effectue cette mission est très professionnelle, la qualité du service  rendu est tout simplement parfaite. Quel changement dans notre quotidien ... Pierre Tixier (56)",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'pascale MOREL',
          date: '01/05/2021',
          temoignage: "Aspects positifs : Ponctualité, Professionnalisme, Qualité, Réactivité. Bonjour, Floriane est intervenue à mon domicile pour différentes prestations. Je tenais à souligner son professionnalisme : très compétente et travail très bien fait, mais également ses qualités humaines : discrète, souriante, sérieuse, ponctuelle, avec toujours des paroles très réconfortantes. Lorsqu'on est en traitement pour une maladie lourde, ses visites étaient toujours un bonheur. Je la remercie",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'valérie noulette',
          date: '01/06/2021',
          temoignage: "Aspects positifs : Professionnalisme. J'ai beaucoup apprécié l'intervention de cette société par le biais de son intervenant Roger qui a su tout à la foi m'écouter alors que je sortais d'hospitalisation à cause de la covid et que mon moral était très bas et faire un excellent travail de nettoyage, de rangement tout en respectant mon intérieur et mon intimité.   Il fut pour moi en cette période de retour à la vie d'après, un réel rayon de Soleil j'attendais avec impatience son intervention.Je recommande vivement cette société et si je devais de nouveau avoir besoin d'aide je n'hésiterais pas à refaire appel à cette société et a demandé Roger.",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Rémi Pourchelle',
          date: '01/06/2021',
          temoignage: "Aspects positifs : Professionnalisme, Qualité.  Mon beau-père a eu droit à quelques heures à domicile et la personne qui est venu, Roger, a été très bien. (Professionnel, Qualité du service, Sympathie, etc ).",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Cedric Hernandez',
          date: '01/05/2021',
          temoignage: "Aspects positifs : Professionnalisme.  Je travail pour cette société depuis pas mal de temp déjà, agréable de pas travailler le week-end. Le taux horaire est respectable.",
          note:5,
          origine: 'Google'
        },
        {
          nom: 'Cedric Hernandez',
          date: '01/05/2021',
          temoignage: "Aspects positifs : Professionnalisme. Je travail pour cette société depuis pas mal de temp déjà, agréable de pas travailler le week-end. Le taux horaire est respectable.",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'hervé desevedavy',
          date: '01/05/2021',
          temoignage: "Aspects positifs : Ponctualité, Professionnalisme, Qualité, Rapport qualité-prix, Réactivité. Nous apprécions beaucoup Mélodie AUBERGER pour ses prestations données.Elle s'adapte à tous les besoins que j'ai et c'est très précieux d'avoir sa présence à mes côtés car je suis seule à gérer tous les travaux d'entretien. Mon mari étant hémiplégique droit est invalide. Mélodie est en plus une compagnie très agréable. Elle est très efficace et minutieuse dans son travail.",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Volo Sylvana',
          date: '01/09/2021',
          temoignage: 'Femme de ménage très investi je recommande cette personne ..gentille et professionnelle. Prenoms..Anais',
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'mannessier Marie-Cécile',
          date: '01/07/2021',
          temoignage: "Excellent travail ,Roger est un employé formidable encore un grand merci à vous",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Celine Gomes',
          date: '01/08/2021',
          temoignage: "Aspects positifs : Ponctualité, Professionnalisme, Qualité, Rapport qualité-prix.  Très contente merci à fanny pour c prestations de qualité. Je recommande h&l. Visité en juillet",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Delphine Santune',
          date: '01/04/2021',
          temoignage: "Aspects positifs : Ponctualité, Professionnalisme, Qualité, Réactivité.  Bonjour, je suis très satisfaite du passage de mon aide ménagère Roger dans le 62, une personne dynamique, rapide, efficace,  spontanée,  et d'une gentillesse irréprochable.  Il mérite mes félicitations pour son travail.",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Victoire Lefebvre',
          date: '01/04/2021',
          temoignage: "Aspects positifs : Ponctualité, Professionnalisme, Réactivité. Suite à l’opération de ma maman, nous avons eu à faire à Roger, personne de bonne présentation, sérieuse, polie, très efficace dans son travail, personne impliquer et à l’écoute des gens. Encore merci pour vos heures chez nous.",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'jeremie dubus',
          date: '01/06/2021',
          temoignage: "Aspects positifs : Ponctualité, Professionnalisme, Réactivité. Roger et venu pour ma mère suite à une hospitalisation. Travail impeccable et soigné. Merci à lui",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Claudine Legrand',
          date: '01/07/2021',
          temoignage: "Un travail impeccable et un jeune toujours ponctuel, motivé et dynamique. Je recommande gianifarese virlat. Dommage que cela ne dure pas plus longtemps.",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Raymonde Oget',
          date: '01/08/2021',
          temoignage: "ANAÏS  c une personne  très agréa.a recommandé a d autre personne pour ses services Très propre et sérieuse .",
          note: 4,
          origine: 'Google'
        },
        {
          nom: 'Sandrine PAUDEX',
          date: '01/08/2021',
          temoignage: "Aspects positifs : Qualité. Très bonne expérience avec Julie qui s’est montrée agréable et compétente",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Thithaine Baurudet',
          date: '01/08/2021',
          temoignage: 'Aspects positifs : Professionnalisme, Qualité, Réactivité. Entreprise réactive, Julie est venu tout de suite, très bon travail',
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Caroline Marais',
          date: '01/08/2021',
          temoignage: "Aurélie vient chez nous depuis plusieurs mois maintenant. Toujours ponctuelle et souriante, le travail est nickel rien à redire.  Merci Aurélie!  Caroline et Nicolas",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Nevo Robert',
          date: '01/08/2021',
          temoignage: "Aspects positifs : Ponctualité, Professionnalisme, Qualité. Saint Pierre Quiberon, le 29 juillet 2021. Suite à deux accidents domestiques mon épouse et moi-même avons bénéficié d’une aide ménagère à domicile. Nous avons été totalement très satisfaits des prestations fournies par Mademoiselle Mélanie GAiN. Ponctualité, efficacité et de plus douée d’une très grande gentillesse.  Un exemple de professionnalisme que je me ferais un plaisir de recommander.  Mr & Mme Robert Névo",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Elena Dupas',
          date: '01/05/2021',
          temoignage: "Aspects positifs : Ponctualité, Professionnalisme, Qualité, Réactivité. J'y travail depuis peut et j'ai rien à leur reprocher , horaire avantageux quand on a des enfants 😁 et salaire intéressant ! Je recommande 😁",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Marie Therese goupil',
          date: '01/07/2021',
          temoignage: "Aspects positifs : Ponctualité, Professionnalisme, Qualité, Rapport qualité-prix. Je suis tres satisfait d aurelie .",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Alissone VIART',
          date: '01/04/2021',
          temoignage: "Très bonne prestation pour ma part. Roger était assidu et rigoureux dans son travail. Rien à redire.",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Btissam Chanfar',
          date: '01/07/2021',
          temoignage: "Aspects positifs : Ponctualité, Professionnalisme, Réactivité. Anaïs as su faire un travail impeccable,elle est ponctuelle professionnel et très rapide je suis satisfaite a 100 %",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Jean Claude Plathey',
          date: '01/07/2021',
          temoignage: "Aspects positifs : Ponctualité, Professionnalisme, Qualité Julie viens une fois par semaine faire le ménage,personne sérieuse,autonome et efficace Visité en juillet",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Annie Moragues',
          date: '01/07/2021',
          temoignage: "Aspects positifs : Ponctualité, Professionnalisme, Qualité, Rapport qualité-prix, Réactivité. Enchantée de l'aide de Floriane. Personne très agréable et efficace dans le travail. Je la recommandé vivement.",
          note: 5,
          origine: 'Google'
        },

        {
          nom: 'Christelle Delabroy',
          date: '01/04/2021',
          temoignage: "J'ai eu la chance d'avoir Roger qui est venu intervenir chez moi ,consciencieux et efficace,rien à dire !!",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Karine Aubursin',
          date: '01/04/2021',
          temoignage: "Aspects positifs : Professionnalisme.  Bonjour je travaille actuellement pour cette société  depuis 11 mois rien a dire . Salaire motivant  personnels au top toujours à l écoute. J adore ce métier.  La société très professionnelle",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'CHRISTOPHE ERPELDIGN',
          date: '01/07/2021',
          temoignage: "Aspects positifs : Ponctualité, Professionnalisme, Qualité, Réactivité. Très bonne prestation d Amandine. Très consciencieuse et sérieuse dans son travail.  Et très rapide dans ses taches. Merci beaucoup 😊",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Laurent SAULNIER',
          date: '01/07/2021',
          temoignage: "Aspects positifs : Ponctualité, Professionnalisme, Qualité. Nous sommes très satisfaits du travail de Floriane qui est souriante et efficace, elle sait s'adapter si nécessaire.",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'angélique alfred',
          date: '01/04/2021',
          temoignage: "Monsieur Roger Lamotte est venu Pendant quelques temps chez moi tout s'est très bien passé il est très professionnel et agreable  . Un travail parfait je le recommande.",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Joel Cour',
          date: '01/07/2021',
          temoignage: "Aspects positifs : Ponctualité, Professionnalisme, Qualité, Rapport qualité-prix, Réactivité. Julie ,aide ménagère qui est intervenue pour moi suite à une opération de la hanche . Une personne très douce et très agréable . Qualité de travail impeccable. Je recommande vivement . Mr COUR JOEL",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Nadege Cocheteau',
          date: '01/08/2021',
          temoignage: "Aspects positifs : Ponctualité, Professionnalisme, Qualité, Réactivité. Ravie du travail de Julie, elle est  très gentille agréable et travaile très bien ma maison est propre.je suis ravie merci à elle.",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'laurence roche',
          date: '01/06/2021',
          temoignage: "Aspects positifs : Ponctualité, Professionnalisme, Qualité, Réactivité. Merci beaucoup pour l'aide et le service de Roger qui a été très à l'écoute de mes parents Mme derenty. Je recommande vivement Roger, je suis très contente.",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Andre-louis Varenne',
          date: '01/07/2021',
          temoignage: 'Aspects positifs : Ponctualité, Professionnalisme, Qualité, Réactivité. Roger est une personne très compétente et très agréable rien à redire sur sa prestation',
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Mariano CORSO',
          date: '01/04/2021',
          temoignage: "Aujourd'hui viens de ce terminer mes 6h de service avec Mme Julie Tognarelli, cela fut un plaisir de la voir travaillez. Efficacité, professionalisme et enthousiasme. Elle a travaillé pour Mme Gomila ma compagne cordialement. LCL(RC) Mariano CORSO.",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Jean Marc Noble',
          date: '01/08/2021',
          temoignage: "Aspects positifs : Ponctualité, Professionnalisme, Qualité. Bonne prestation, travail soigné, ponctuelle. Aurelie",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'marie clement',
          date: '01/09/2021',
          temoignage: "Aspects positifs : Ponctualité, Professionnalisme, Qualité, Rapport qualité-prix, Réactivité. Roger  et un bonne élément. Travail soigner et sérieux. Encore merci pour son professionnalisme",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'martine laudo',
          date: '01/07/2021',
          temoignage: "Aspects positifs : Professionnalisme. Anais , prestataire tres compétante ,ravis d'avoir eu ces service",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Hippo Services',
          date: '01/05/2021',
          temoignage: "Aspects positifs : Ponctualité, Professionnalisme, Qualité, Réactivité. Aurélie. Super service. oger l’aide à domicile très professionnel et sérieux",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Michel Dehee',
          date: '01/06/2021',
          temoignage: "Aspects positifs : Ponctualité, Professionnalisme, Qualité. aurelie tres bonne prestation tres gentile",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Zina Mokhnachi',
          date: '01/06/2021',
          temoignage: "Aspects positifs : Ponctualité, Professionnalisme, Qualité. Bonjour suite à notre demande auprès de la mutuelle Aurélie et venu nous aider une femme vraiment que je recommande souriante à l’écoute ponctuelle sont travail au tooop après sont passage chaque pièce brille même Les portes merci à toi Aurélie vous allez pas être déçu 👌",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Danielle Heyman',
          date: '01/07/2021',
          temoignage: "Bonne  préparation  de roger  suite a mon opération  je suis satisfaite",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Emmanuel GOIN',
          date: '01/06/2021',
          temoignage: "Aspects positifs : Ponctualité, Professionnalisme. Intervenante professionnelle et ponctuelle. Travail bien fait. Merci Floriane Nouvet",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'joelle georget',
          date: '01/07/2021',
          temoignage: "Amandine est une personne compétente et dynamique professionnelle qui inspire confiance",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'celine clochet',
          date: '01/05/2021',
          temoignage: " Aspects positifs : Ponctualité, Professionnalisme, Qualité, Réactivité. Amandine est venue avec professionnalisme et beaucoup de gentillesses à recommander chaudement",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Marie Robert',
          date: '01/06/2021',
          temoignage: "Aspects positifs : Ponctualité, Professionnalisme, Qualité, Réactivité. Professionnel agréable,  dans l échange, l écoute et répondant à la demande. Professionnel : cedric",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Claudie METHE RIALHON',
          date: '01/05/2021',
          temoignage: "Très bon travail de Floriane à notre domicile depuis de nombreuses années. Merci.",
          note: 4,
          origine: 'Google'
        },
        {
          nom: 'Herve Lebreton',
          date: '01/08/2021',
          temoignage: "il y a un mois. Aspects positifs : Ponctualité, Professionnalisme. Mélanie Gain est intervenue dans le cadre de la mutuelle. Très satisfait.",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Michèle HOFFMANN',
          date: '01/06/2021',
          temoignage: "Aspects positifs : Qualité. Excellente prestation de Anaïs",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Patricia Bourdillon',
          date: '01/05/2021',
          temoignage: "Bonjour.  Nous faisons tout nos compliments à Mme FlorianeNouvet  pour son sérieux dans son travail et sa ponctualité Mr et Mme Fructuoso",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Mathieu Metzger',
          date: '01/05/2021',
          temoignage: "Aspects positifs : Ponctualité, Professionnalisme, Qualité, Réactivité. Bonne prestation , facilement joignable. Anaïs ponctuelle et travail efficace , a recommandé",
          note: 4,
          origine: 'Google'
        },
        {
          nom: 'Carol Seignovert',
          date: '01/06/2021',
          temoignage: "Aspects positifs : Professionnalisme.  Bonjour, J'ai eu recours à H@L avec comme aide Mme Floriane Nouvet , rien a reprocher le professionnalisme et la réactivité au rdv.",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'valerie P.',
          date: '01/05/2021',
          temoignage: "Aspects positifs : Ponctualité, Professionnalisme. Entreprise où il fait bon travailler à l'écoute de son personnel",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Anne-Marie BIGNOLAS',
          date: '01/04/2021',
          temoignage: "Aspects positifs : Ponctualité, Professionnalisme, Réactivité. Bonjour, mon expérience avec cette société est très bonne, la dame qui vient faire le ménage chez ma mère est très souriante, ponctuelle et compétente. je suis très satisfaite de la prestation.",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Jean francois Barbe',
          date: '01/06/2021',
          temoignage: "Aspects positifs : Ponctualité, Professionnalisme, Qualité, Rapport qualité-prix, Réactivité. Très content des prestations faîtes par Floriane, toujours ponctuel et sérieuse. Je recommande",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Sylvie Delansay',
          date: '01/06/2021',
          temoignage: "Aspects positifs : Ponctualité, Professionnalisme, Qualité, Réactivité.  Une mention spéciale à Roger (La Gorgue - Nord) pour la qualité de ses prestations et sa gentillesse auprès de notre tante. Nous recommandons vivement 👍!!",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Corinne Lenoir',
          date: '01/06/2021',
          temoignage: "Aspects positifs : Ponctualité, Professionnalisme, Réactivité. Roger  est intervenu chez ma mère. Personne très attentive. Au besoin des taches  a faire pas besoins de faire ses preuves elles sont faites. Personne très aimableet serieuse",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Jeanine Garreau',
          date: '01/06/2021',
          temoignage: "Aspects positifs : Ponctualité, Professionnalisme.  Très satisfaite de Mélodie Auberger pour son travail et sa gentillesse.",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'denise meillarec',
          date: '01/07/2021',
          temoignage: "Aspects positifs : Ponctualité, Professionnalisme, Qualité, Réactivité.  Les prestations fournies par Mélanine Gain intervenue par AXA Mutuelle sont d'une efficacité exemplaire.",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'prodhomme stephane',
          date: '01/04/2021',
          temoignage: "Teddy Dreux  bonne prestation",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Lucille Lachkar',
          date: '01/07/2021',
          temoignage: "Floriane motivée et bien sympathique !",
          note: 4,
          origine: 'Google'
        },
        {
          nom: 'Maxime Martin',
          date: '01/06/2021',
          temoignage: "Aspects positifs : Ponctualité, Professionnalisme, Qualité. Merci à Floriane pour son amabilité. Merci de nous avoir aidé durant cette période.",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'ianis gaming',
          date: '01/05/2021',
          temoignage: "Aspects positifs : Ponctualité, Qualité. Très satisfait de la prestation, Amandine est professionnelle et ponctuelle. Merci pour votre aide.",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Justine .blejsz',
          date: '01/06/2021',
          temoignage: "Aspects positifs : Qualité.  Roger très bonne prestation et très professionnel",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Bart Véronique',
          date: '16/06/2021',
          temoignage: "Roger s'est occupé de ma maman pendant plusieurs semaines. Jai apprécié sa gentillesse, son proffessionalisme , son humanité, sa ponctualité, il a été un grand soutien pour ma maman. On a aimé sa rigueur, son soucis de la propreté. Je le recommande à 100%.",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Catherine Blary',
          date: '01/04/2021',
          temoignage: "Aspects positifs : Ponctualité, Professionnalisme, Réactivité.  Roger est très  méticuleux dans son travail et très concentieux je vous le conseil . Merci  Roger",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'sebastien guillem',
          date: '01/06/2021',
          temoignage: "Aspects positifs : Professionnalisme, Qualité.  Bonjour, La personne qui s occupe de l entretient de mon logement est très professionnelle, toujours a l heure, cela fait 2 ans qu' elle vient toutes les semaine et on a jamais été déçu.",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Marig OLIVIER',
          date: '01/07/2021',
          temoignage: "très contente des prestations de mélanie gain , intervenue par votre entreprise suite à une hospitalisation  et que j'ai demandé  à conserver par la suite  et je suis tout à fait satisfaite de ses services depuis ce moment .",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Othon Bieber',
          date: '01/06/2021',
          temoignage: "Aspects positifs : Professionnalisme. Très bonne prestation de Anaïs",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Anthony Michel',
          date: '01/05/2021',
          temoignage: "Prestations parfaites (Floriane Nouvet)",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Nicole Gayet',
          date: '01/05/2021',
          temoignage: "Aspects positifs : Ponctualité, Professionnalisme, Qualité, Rapport qualité-prix, Réactivité. Très satisfaite de la prestation de Floriane Nouvet",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'marie-c.boisson@orange.fr',
          date: ' 2021-07-29T20:45:00',
          temoignage: "merci à Julie qui est intervenue à mon domicile personne sérieuse et agréable",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Paulette Palmer',
          date: '01/06/2021',
          temoignage: "Aspects positifs : Ponctualité, Professionnalisme, Réactivité. Contente de l'intervention de Mélanie G de vannes, suite à une opération ou j'ai eu des heures de ménage par ma mutuelle.",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'monique PFRUNNER',
          date: '01/05/2021',
          temoignage: "Aspects positifs : Qualité. J'ai eu Anaïs , avis favorable",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'marie palmeri',
          date: '01/08/2021',
          temoignage: "Très satisfaite de la prestation d'Anais",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Hélène BAILLY',
          date: '01/05/2021',
          temoignage: "Aspects positifs : Professionnalisme, Qualité, Pour l'intervention de Floriane NOUVET",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Hélène BAILLY',
          date: '01/05/2021',
          temoignage: "Aspects positifs : Professionnalisme, Qualité. Pour l'intervention de Floriane NOUVET",
          note: 4,
          origine: 'Google'
        },
        {
          nom: 'Anne renée Pras',
          date: '01/05/2021',
          temoignage: "Aspects positifs : Qualité. Bien contente des interventions  de Floriane",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Pierre Calmbacher',
          date: '01/04/2021',
          temoignage: "Aspects positifs : Ponctualité, Professionnalisme, Qualité. Je suis content de la prestation de Mélanie gain , l'aide ménagère et de la société .",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Corine Delis',
          date: '01/05/2021',
          temoignage: "Aspects positifs : Ponctualité, Professionnalisme, Qualité, Réactivité. Très satisfaite du travail d'Amandine.",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Joseph Altenbourger',
          date: '01/08/2021',
          temoignage: "Aspects positifs : Ponctualité, Qualité, Réactivité. Client satisfait du travail de nolwenn",
          note: 5,
          origine: 'Google'
        }
        ,
        {
          nom: 'Jean Vermeulen',
          date: '01/07/2021',
          temoignage: "Aspects positifs : Ponctualité, Professionnalisme, Qualité. satisfait de Roger suite à mon  hispitaliation",
          note: 5,
          origine: 'Google'
        }
        ,
        {
          nom: 'Rosalba Gamel',
          date: '01/08/2021',
          temoignage: "Aspects positifs : Qualité. Très satisfaite de Anaïs",
          note: 5,
          origine: 'Google'
        },
        {
          nom: 'Lundiez August',
          date: '01/09/2019',
          temoignage: "Super entreprise - Réactifs et qualitatifs. je conseille - Mon aide ménagère est au top",
          note: 5,
          origine: 'Google'
        },
        {
          temoignage:
            "Rosa est un très bons élément, je suis très satisfaite d'elle, c'est la raison pour laquelle je prends un contrat chez H et L à durée indéterminée.",
          nom: 'Fabienne M.',
          note: 4
        },
        {
          temoignage:
            "Ma mère a obtenu 8 heures d'aide ménagère par sa mutuelle. Mélanie Gain est donc intervenue , c'est une personne très agréable, très professionnelle, ponctuelle et je n'hésiterai pas à la recontacter en cas de besoin par la suite auprès de ma mère. Un grand merci pour son travail soigné et sa gentillesse.",
          nom: 'N. R.',
          note: 5
        },
        {
          temoignage:
            'Mme N. K. a été très bénéfique à ma convalescence. Vis-à-vis de son travail d’aide ménagère, pour sa qualité et son assiduité, ainsi que de sa bonne humeur, je me permets de vous recommander auprès de plusieurs autres personnes.',
          nom: 'M. DEBALD',
          note: 4
        },
        {
          temoignage:
            "Nous sommes très très satisfaits de l'intervention de Me TIPHANIE : travail bien fait,  toujours à l'heure. De plus on avait pas besoins de lui dire ce qu'il y avait à faire. Je la conseille à d'autre couples car toujours plaisante et souriante.",
          nom: 'M. Desnouveaux',
          note: 3
        },
        {
          temoignage:
            'L’intervenante qui est venue était parfaite, dynamique, rapide et travailleuse. Je vous remercie pour cette mise en place parfaite.',
          nom: 'C. P',
          note: 4
        },
        {
          temoignage:
            'Je vous contacte pour vous dire que Marion est très professionnelle et efficace, nous sommes très contents de sa prestation.',
          nom: 'Mme V.',
          note: 5
        },
        {
          temoignage:
            'Très bon travail, ponctuelle dans ses rendez-vous, ce que nous aimons mon épouse et moi-même. Très plaisante à vivre, apporte la Joie de Vivre.',
          nom: 'M. Desert',
          note: 5
        },
        {
          temoignage:
            'Travail parfait. Brigitte est exceptionnelle, elle ne laisse rien passer. Serviable, autonome, d’une très grande gentillesse et d’une amabilité incomparable. Il s’agit d’une vraie pro',
          nom: 'M. Wallet',
          note: 4,
        },
      ];

/* eslint-disable eqeqeq */
import { IonLoaderService } from 'src/app/services/ion-loader/ion-loader.service';
/* eslint-disable radix */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { ApiService } from '../services/api/api.service';
import { AuthenticationStateService } from '../services/authentication-state/authentication-state.service';
import { Subscription } from 'rxjs';
import { UserService } from '../services/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  nbNotifsNonLues = 0;
  getNotificationsOfUserSub: Subscription;

  agent;

  typeAgenda = 'day';
  typeBilan: 'day'|'week'|'month'|'year' = 'month';

  weekDate = new Date();
  hasLocationAccepted:boolean;
  prolonger = false;
  mission_signed = false;
  missionCommentaires = false;

  bar = 1;
  periodeIndispo = 1;
  barIndispo = 1;

  lat_actuelle;
  lng_actuelle;

  postesLoader = false;
  transportsLoader = false;

  /**
   * Periode d'indisponibilité
   */
  periode = {debut: null, fin: null, time_debut: '08:00', time_fin: '08:00'};

  /**
   *
   * ION DATETIME    NAME
   */
  ionDate = {
    // eslint-disable-next-line max-len
    monthNames: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'aout', 'septembre', 'octobre', 'novembre', 'décembre' ],
    monthShortNames: ['jan', 'fev', 'mar', 'avr', 'mai', 'jui', 'jui', 'aou', 'sep', 'oct', 'nov', 'dec' ],
    dayNames:['dimanche','lundi','mardi','mercredi','jeudi','vendredi','samedi'],
    dayShortNames:['dim','lun','mar','mer','jeu','ven','sam']
  };

  stateBackgroundGeolo = false;

  showQrCodePreview = false;

  // Candidature
  DATA_CANDIDATURE_DEFAULT = {
    postes: [],
    transports: null,
    distance: environment.perimetreRechercheDefault,
    duree_travail: 1,
    dispo: '',
    disponibilites: null,
    accept: false,
    receive: false,
    civilite: '',
    nationalite: '',
    birthplace: '',
    country: 'France',
    annonce_title: '',
    annonce_short_description: '',
  };
  DATA_CANDIDATURE: CANDIDATURE = { ...this.DATA_CANDIDATURE_DEFAULT }; // Clone le contenu par défaut des datas canditatures
  ERRORS = {
    champsError: [],
    candidature: null,
    candidature_form: [],
    searchCandidat: null,
    searchOffre: null,
  };
  DEFAULT_EVENTS = [];
  isSubmittedStep1 = false;
  isSubmittedStep2 = false;

  CANDIDATURE_STEP = 'STEP1';
  CANDIDATURE_UPLOADS_STEP = 1;
  CANDIDATURE_UPLOADS_STEP_ALERT = null;
  CANDIDATURE_UPLOADS_STEP_ALERT_CLASS = 'alert-success';

  HEURES_RANGER: Array<any> = [
    { value: 0, legend: '00:00' },
    { value: 0.5, legend: '00:30' },
    { value: 1, legend: '01:00' },
    { value: 1.5, legend: '01:30' },
    { value: 2, legend: '02:00' },
    { value: 2.5, legend: '02:30' },
    { value: 3, legend: '03:00' },
    { value: 3.5, legend: '03:30' },
    { value: 4, legend: '04:00' },
    { value: 4.5, legend: '04:30' },
    { value: 5, legend: '05:00' },
    { value: 5.5, legend: '05:30' },
    { value: 6, legend: '06:00' },
    { value: 6.5, legend: '06:30' },
    { value: 7, legend: '07:00' },
    { value: 7.5, legend: '07:30' },
    { value: 8, legend: '08:00' },
    { value: 8.5, legend: '08:30' },
    { value: 9, legend: '09:00' },
    { value: 9.5, legend: '09:30' },
    { value: 10, legend: '10:00' },
    { value: 10.5, legend: '10:30' },
    { value: 11, legend: '11:00' },
    { value: 11.5, legend: '11:30' },
    { value: 12, legend: '12:00' },
    { value: 12.5, legend: '12:30' },
    { value: 13, legend: '13:00' },
    { value: 13.5, legend: '13:30' },
    { value: 14, legend: '14:00' },
    { value: 14.5, legend: '14:30' },
    { value: 15, legend: '15:00' },
    { value: 15.5, legend: '15:30' },
    { value: 16, legend: '16:00' },
    { value: 16.5, legend: '16:30' },
    { value: 17, legend: '17:00' },
    { value: 17.5, legend: '17:30' },
    { value: 18, legend: '18:00' },
    { value: 18.5, legend: '18:30' },
    { value: 19, legend: '19:00' },
    { value: 19.5, legend: '19:30' },
    { value: 20, legend: '20:00' },
    { value: 20.5, legend: '20:30' },
    { value: 21, legend: '21:00' },
    { value: 21.5, legend: '21:30' },
    { value: 22, legend: '22:00' },
    { value: 22.5, legend: '22:30' },
    { value: 23, legend: '23:00' },
    { value: 23.5, legend: '23:30' },
    { value: 24, legend: '24:00' },
    // { value: 24.5, legend: '24:30h' }
  ];

  listLieuxNaissances = [];
  listNationalites = [];

  saveModalPreSelectFrequenceLoader = false;
  selectInfoEnCours;
  listRecurences = [
    { id: 4, label: 'Toutes les semaines' },
    { id: 2, label: 'Toutes les 2 semaines' },
    { id: 1, label: 'Toutes les 3 semaines' },
  ];
  detailsDispo = {
    frequence: '4',
    semaine_paire: '',
    date_debut_dispo: '',
  };

  constructor(
 
    private toastController: ToastController,
    private api: ApiService,
    private ionLoaderService: IonLoaderService,
    private authenticationStateService: AuthenticationStateService
    ) { }

  /**
   * Obtenir le mois et l'annnée d'une date donnée
   * @param date objet date à utiliser
   * @returns string
   */
    getMonthYearString(date: Date): string {
      return `${date.toLocaleString('fr-FR', {weekday: 'long', day: 'numeric', month: 'long'})} ${date.getFullYear()}`
    }

   /**
    * Afficher le toast
    * @param msg le message à afficher
    * @param time le temps d'affichage, c'est 2000 par defaut
    */
    async presentToast(msg, time = 2000): Promise<void> {
      const toast = await this.toastController.create({
        message: msg,
        duration: time,
        cssClass: 'w-toast'
      });
      toast.present();
    }

    showCamera() {
      this.showQrCodePreview = true;
      // this.modalController.dismiss();
      ((window as any).document.querySelector('ion-app') as HTMLElement).classList.add('cameraView');
      ((window as any).document.querySelector('body') as HTMLElement).classList.add('cameraViewBody');
    }

    hideCamera() {
      this.showQrCodePreview = false;
      ((window as any).document.querySelector('ion-app') as HTMLElement).classList.remove('cameraView');
      ((window as any).document.querySelector('body') as HTMLElement).classList.remove('cameraViewBody');
    }


    /**
     * Verifie si l'index existe
     * @param array tableau d'objet à manipuler
     * @param index valeur de l'index
     * @returns boolean true ou false selon l'existance de l'element
     */
    OnCheckIndex(array: any[], index): boolean {
      let value = false;
      array.forEach((ar, i) =>{  value = i === index ? true : value; console.log('i', i); console.log('index', index); } );
      return value;
    }

    /**
   *  vérification de numéro de sécurité sociale
   * @param value numéro entré
   * @return true | false
   */
  checkNSS(value: string): boolean {
    let isValid = true;
    const reg = new RegExp('^[12]\\d{14}$');
    if (value.match(reg)) {
      const partial = value.substring(0, 13);
      const clef = 97 - (parseFloat(partial) % 97);
      // tslint:disable-next-line:radix
      if (clef !== parseInt(value.substring(13, 15))) {
        isValid = false;
      }
    } else {
      isValid = false;
    }
    return isValid;
  }

  checkIfMobileTablet(): boolean {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return true;
    }
    else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
        return true;
    } else {
        return false;
    }
  }

  /**
   * Verifier si deux tableaux sont meme
   * @param x premier tableau
   * @param y deuxieme tableau
   */
  arraysAreSame(x, y): boolean {
    if(!x || !y){
      return false;
    }
    let objectsAreSame = true;
    if (x.length !== y.length) {
      objectsAreSame = false;
    } else {
      for (let index = 0; index < y.length; index++) {
        if (x[index] !== y[index]) {
          objectsAreSame = false;
          break;
        }
      }
    }
    return objectsAreSame;
  }

  /**
   * Verifier la duree minimum de 2H des evenements
   * @return false
   */
  checkEventDuration(): boolean {
    const less30 = this.DATA_CANDIDATURE.events_dispos.find(
      ev => {
        if (moment(ev.end).diff(moment(ev.start), 'minutes') <= 30) {
          return true;
          // mEnd = moment(selectInfo.end).add(30, 'minutes');
          // if (add1hour) {
          //  mEnd.add(1, 'hour');
          // }
        }
      }
    );
    return less30 ? true : false;
  }

  /**
   * Verifier si les horraires se chevauche sur une journée
   * @return boolean
   */
  checkChevauchementHoraire(): boolean {
    let state = false;
    let i = 0;
    this.DATA_CANDIDATURE.events_dispos?.forEach(
      (ev) => {
            let deb = +ev.start.split(':')[0];
            deb = +ev.start.split(':')[1] > 0 ? deb + 0.5 : deb;
            let fin = +ev.end.split(':')[0];
            fin = +ev.end.split(':')[1] > 0 ? fin + 0.5 : fin;
            const find = this.DATA_CANDIDATURE.events_dispos.find((eve) => {
            let deb1 = +eve.start.split(':')[0];
            deb1 = +eve.start.split(':')[1] > 0 ? deb1 + 0.5 : deb1;
            let fin1 = +eve.end.split(':')[0];
            fin1 = +eve.end.split(':')[1] > 0 ? fin1 + 0.5 : fin1;
            if (eve !== ev && ev.day === eve.day && ((deb1 <= deb && fin1 > deb) || (deb1 < fin && fin1 > deb))) {
              return true;
            } else {
              return false;
            }
        });
        if (find) {
          // state = true;
          // break;
          i++;
        }
      }
    );
    if (i > 0) {
      state = true;
      // break;
    } else {
      state = false;
    }
    return state;
  }

  getSecondBeetweenTwoDates(d1: Date, d2: Date): number {
    const dif = d1.getTime() - d2.getTime();
    const seconds = dif / 1000;
    return Math.abs(seconds);
  }

  getLegend(value: number): string {
    if(!value){
      return '';
    }
    return this.HEURES_RANGER.filter((h) => h.value === value)[0].legend;
  }

  async updateCandidat(successFunction = null){
    if(!this.DATA_CANDIDATURE.candidatId){
      return;
    }
    const datas = {
      id: this.DATA_CANDIDATURE.candidatId,
      cv_filename: this.DATA_CANDIDATURE.cv_filename,
      pass_sanitaire_filename: this.DATA_CANDIDATURE.pass_sanitaire_filename,
      photo_profil_filename: this.DATA_CANDIDATURE.photo_filename,
      video_presentation_filename: this.DATA_CANDIDATURE.video_filename,
      description: this.DATA_CANDIDATURE.description,
      step_form_candidature: this.CANDIDATURE_STEP
    };
    await this.ionLoaderService.simpleLoader('Sauvegarde en cours...');
    this.api.updateCandidat(datas).subscribe((resp) => {
      if(resp && resp?.success){
        console.log('UPDATE CANDIDAT', resp);
        this.getDetailsAgent();

        if(successFunction){
          successFunction();
        }
      }else{
        this.presentToast('Erreur lors de la mise à jour');
      }
      this.ionLoaderService.dismissLoader();
    });
  }
  getDetailsAgentSub:Subscription;
  async getDetailsAgent(){
    // await this.ionLoaderService.simpleLoader('Chargement en cours...');
    const agentId = this.authenticationStateService.userValue?.candidat_id;
    this.getDetailsAgentSub = this.api.getCandidat(agentId).subscribe(async (resp) => {
      if(resp && resp?.success){
        console.log('resp',resp);
        this.agent = resp.data;
  
 
      }else{
        this.presentToast('Erreur lors de la récupération de vos informations');
      }
      // await this.ionLoaderService.simpleLoader('Chargement en cours...');
    });
  }
  
  async getNotificationsOfUser(){
    this.nbNotifsNonLues = 0;
    const userId = this.authenticationStateService.userValue?.id;
    this.getNotificationsOfUserSub = this.api.getNotificationsOfUser(userId).subscribe(async (resp) => {
      if(resp && resp?.success){
        resp.datas.forEach(notif => {
          if(!notif.is_read){
            this.nbNotifsNonLues++;
          }
        });
      }else{
        this.presentToast('Erreur lors de la récupération des notifications');
      }
    });
    console.log('nbNotifsNonLues',this.nbNotifsNonLues);
  }

  getTitlePlusDispo(ev){
      let titlePlus = '';
      if(ev.frequence == '2' && ev.semaine_paire == '1'){
        titlePlus = ' (les semaines paires uniquement)';
      }else if(ev.frequence == '2' && ev.semaine_paire == '0'){
        titlePlus = ' (les semaines impaires uniquement)';
      }else if(ev.frequence == '1' && ev.date_debut_dispo){
        titlePlus = ' (toutes les 3 semaines, à partir du '+(moment(ev.date_debut_dispo).format('DD/MM/YYYY'))+')';
      }
      return titlePlus;
    }

    getTitlePlusDispoShort(ev){
      let titlePlus = '';
      if(ev.frequence == '2' && ev.semaine_paire == '1'){
        titlePlus = ' (paires)';
      }else if(ev.frequence == '2' && ev.semaine_paire == '0'){
        titlePlus = ' (impaires)';
      }else if(ev.frequence == '1' && ev.date_debut_dispo){
        titlePlus = ' ('+(moment(ev.date_debut_dispo).format('DD/MM/YYYY'))+')';
      }
      return titlePlus;
    }


}

export interface CANDIDATURE {
  candidatId?: number;
  nom?: string;
  prenom?: string;
  email?: string;
  adresse?: string;
  code_postal?: string;
  description?: any;
  ville?: string;
  phone?: number;
  birthday?: any;
  birthplace?: string;
  birthcity?: any;
  num_secu?: any;
  num_travail?: any;
  country?: any;
  password?: any;
  confirm_password?: any;
  postes?: any[];
  transports?: any;
  distance?: number;
  disponibilites?: any;
  duree_travail?: any;
  dispo?: any;
  disponibilite_date?: any;
  accept?: boolean;
  receive?: boolean;
  cv?: any;
  cv_uploade?: any;
  cv_filename?: any;
  cv_error?: boolean;
  pass_sanitaire?: any;
  pass_sanitaire_uploade?: any;
  pass_sanitaire_filename?: any;
  video?: any;
  video_uploade?: any;
  video_filename?: any;
  photo?: any;
  photo_uploade?: any;
  photo_filename?: any;
  civilite?: string;
  nationalite?: any;
  lat?: any;
  lng?: any;
  events_dispos?: any[];
  jobPlace?: any;
  annonce_title?: string;
  annonce_short_description?: string;
  annonce_zip?: any;
  annonce_town?: string;
  lieu_travail?: string;
}

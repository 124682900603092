import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy, Platform } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import { AgmCoreModule } from '@agm/core';            // @agm/core
import { AgmDirectionModule } from 'agm-direction';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BilanService } from './services/bilan/bilan.service';
import { QRScanner } from '@ionic-native/qr-scanner/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { Base64ToGallery } from '@ionic-native/base64-to-gallery/ngx';
import { FileChooser } from '@ionic-native/file-chooser/ngx';
import { IOSFilePicker } from '@ionic-native/file-picker/ngx';
import { MediaCapture } from '@ionic-native/media-capture/ngx';
import { AppService } from './services/app.service';
import { PDFGenerator } from '@ionic-native/pdf-generator/ngx';
import { File } from '@ionic-native/file/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { environment } from 'src/environments/environment';
import { BackgroundGeolocation } from '@awesome-cordova-plugins/background-geolocation/ngx';
import { SafePipe } from './pipes/safe/safe.pipe';
import { CheckAllHorrairePipe } from './pipes/check-all-horraire/check-all-horraire.pipe';
import { DateMissionPipe } from './pipes/date-mission/date-mission.pipe';
import { DaysPipe } from './pipes/days/days.pipe';
import { NoSecondPipe } from './pipes/no-second/no-second.pipe';
import { ToUtf8Pipe } from './pipes/to-utf8/to-utf8.pipe';
import { UserService } from './services/user/user.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthHeaderInterceptor } from './interceptors/auth-header/auth-header';
import { HttpError } from './interceptors/httperror';
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx';
import { LaunchNavigator } from '@awesome-cordova-plugins/launch-navigator/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
// import { FCM } from '@awesome-cordova-plugins/fcm/ngx';
import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
 
FullCalendarModule.registerPlugins([
  dayGridPlugin,
  timeGridPlugin,
  listPlugin,
  interactionPlugin
]);

// eslint-disable-next-line max-len
const config: SocketIoConfig = { url: (localStorage.getItem('ENV') && localStorage.getItem('ENV') === 'TEST') ? environment.urlSocketImageProd : environment.urlSocket, options: {} };

@NgModule({
  declarations: [
    AppComponent,
    CheckAllHorrairePipe,
    DateMissionPipe,
    DaysPipe,
    NoSecondPipe
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    FullCalendarModule,
    AgmCoreModule.forRoot({ // @agm/core
      apiKey: 'AIzaSyCPYUCP5IJKGRXu53J1rLIX3EivrxhxXOA',
      libraries: ['places']
    }),
    ReactiveFormsModule,
    FormsModule,
    AgmDirectionModule,
    SocketIoModule.forRoot(config),
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    BilanService,
    QRScanner,
    Camera,
    AndroidPermissions,
    Base64ToGallery,
    FileChooser,
    IOSFilePicker,
    MediaCapture,
    PDFGenerator,
    File,
    AppService,
    StatusBar,
    Geolocation,
    BackgroundGeolocation,
    OpenNativeSettings,
    LaunchNavigator,
    UserService,
    InAppBrowser,
    FCM,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpError,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHeaderInterceptor,
      multi: true,
    },
    Diagnostic
    // LocalNotifications,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
